<template>
    <div>
        <v-form class="wrapperForm" ref="form" lazy-validation>
            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title">{{$t("Комментарии")}}</div>
                            <div v-if="dataSource.length > 0" class="dialogWrapper">
                                <template v-for="(comment, index) in dataSource">
                                    <div :key="comment.Id" :class="[getCommentClass(comment), (index > 0 && dataSource[index - 1].AuthorId == comment.AuthorId) ? 'noCommentDecor' : '']">
                                        <i v-if="(((index > 0 && dataSource[index - 1].AuthorId != comment.AuthorId) || index == 0) && comment.AuthorPhoto == '') || (comment.AuthorPhoto == '' && ((index > 0 && dataSource[index - 1].AuthorId != comment.AuthorId) || index == 0))" class="fas fa-user-circle personCommentNoPhoto" @click="produceGlobalEvent(comment.AuthorId)"></i>

                                        <v-img
                                            v-if="((index > 0 && dataSource[index - 1].AuthorId != comment.AuthorId) || index == 0) && comment.AuthorPhoto != ''"
                                            :src=comment.AuthorPhoto
                                            height="40"
                                            max-width="40"
                                            class="rounded-circle personCommentPhoto"
                                            @click="produceGlobalEvent(comment.AuthorId)"
                                        >
                                        </v-img>

                                        <div class="d-title d-flex"> 
                                            <div 
                                                v-if="!isMessageVisible(comment) && ((index > 0 && dataSource[index - 1].AuthorId != comment.AuthorId) || index == 0)"
                                            >
                                                <!--<v-workplace-chip :name="comment.AuthorName" :id="comment.AuthorId"/>-->                                          
                                                {{ comment.AuthorName }} ({{comment.EnterpriseName}})
                                            </div>
                                            <div 
                                                v-if="(!comment.IsNew && comment.Attachment && comment.AuthorId == userInfo.workplaceId) || isMessageVisible(comment)"
                                                class="dialog-file-actions ml-auto"
                                            >
                                                <span 
                                                    v-tooltip.left-center="$t('Удалить')"
                                                    @click.stop="deleteAttchment(comment)"
                                                >
                                                    <i class="fas fa-times dark-gray-color"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <template v-if="comment.Attachment">
                                            <v-img
                                                v-if="isPicture(comment.Attachment.Extension)"
                                                contain
                                                :src="imgPreviewURL(comment.Attachment.Id, comment.Attachment.Name)"
                                                lazy-src="@/assets/placeholder-png-image.png"
                                                height="100"
                                                width="100"
                                                @click="loadAttchment(comment.Attachment)"
                                                class="cursor-pointer"
                                                transition="scale-transition"
                                            >
                                            </v-img>
                                            <v-btn
                                                v-else
                                                class="card-link-text btn-as-link"
                                                @click="loadAttchment(comment.Attachment)"                                            
                                                text
                                                plain
                                                :disabled="isProgressVisible(comment)"
                                            >
                                                {{comment.Attachment.Name}}
                                            </v-btn>
                                        </template>
                                        <div v-else class="d-content">{{ comment.CommentText }}</div>
                                        <div class="d-flex">
                                            <div>
                                                <div 
                                                    v-if="isMessageVisible(comment)"    
                                                    class="dialog-file-err-msg"
                                                >
                                                    {{ comment.Attachment.Message }}
                                                </div>
                                                <div 
                                                    v-else-if="isProgressVisible(comment)" 
                                                    class="dialogFileProgressWrap"
                                                >
                                                    <v-progress-linear
                                                        v-model="comment.Attachment.Progress"
                                                        color="#7fc23399"
                                                        height="2"
                                                    ></v-progress-linear>
                                                </div>
                                                <div v-else>
                                                    <span v-if="comment.Attachment">{{ parseInt(commentAttachmentLength(comment)) ? `${(parseFloat(commentAttachmentLength(comment)) / (1024 * 1024)).toFixed(2)} ${$t('МБ')}` : '-' }}
                                                    </span>    
                                                </div> 
                                            </div>
                                            <div class="ml-auto">
                                                {{formatDate(comment.CommentDate, 'DD.MM.YYYY HH:mm')}}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <template v-if="showInteractions">
                                <v-row class="full-width-row" dense>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                            v-model="comment"
                                            rows="3"
                                            no-resize
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            :rules="requiredRule"
                                            @keydown.enter="handleEnterTextarea"
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" class="d-flex flex-wrap align-center justify-end dialog-actions-wrapper">
                                        <v-btn 
                                            @click="addFile"
                                            v-tooltip.left-center='$t("Прикрепить_файл")'
                                            class="middle-green-button"
                                            color="teal"
                                            small 
                                            dark
                                            depressed
                                        > 
                                            <i class="fas fa-cloud-upload-alt"></i> {{ $t("Прикрепить_файл") }}
                                        </v-btn>
                                        <v-btn 
                                            @click="addComment"
                                            :disabled="savingComment"
                                            v-tooltip.left-center='$t("Добавить_комментарий")'
                                            class="middle-green-button"
                                            color="teal"
                                            small 
                                            dark
                                            depressed
                                        > 
                                            <i class="fas fa-paper-plane"></i> {{ $t("Отправить") }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <CommentPicturePreview ref="CommentPicturePreviewRef" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
//import sys from "@/services/system";
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import moment from 'moment';
import CommentPicturePreview from '@/components/dialogs/CommentPicturePreview';

export default {
    name: "document-comments-tab",
    components: {
        CommentPicturePreview
    },
    data() {
        return {
            comment: null,
            pictureExtensions: ['jpg', 'jpeg', 'gif', 'png', 'apng', 'svg', 'bmp'],
            savingComment: false,
        }
    },
    computed: {
        ...mapGetters('auth', { userInfo: 'getUserInfo', isCommonServiceEnabled: 'isCommonServiceEnabled' }),
        ...mapGetters('actionsource', { getDocumentId: 'getDataSourceDocumentId', isDataSourceCommon: 'isDataSourceCommon', isDocumentIsArchive: 'isDocumentIsArchive' }),
        ...mapGetters('documentcomments', { dataSource: 'getDataSource', isPending: 'getIsPending' }),
        ...mapGetters({ requiredRule: 'getRequiredRule' }),
        showInteractions() {
            if (this.isDataSourceCommon && !this.isCommonServiceEnabled)
                return false;
            
            if (this.isDocumentIsArchive)
                return false;
                
            return true;
        }
    },
    methods: {
        ...mapActions('documentcomments', ['updateDataSource', 'setIsActive']),
        ...mapActions('actionsource', ['AddDocumentCommentFromTab', 'AddFileToComment', 'updatePageCounter']),
        async update() {            
            await this.updateDataSource();
        },
        getCommentClass(comment) {
            return comment.AuthorId == this.userInfo.workplaceId ? 'dialog dialog-2 dialog-50' : 'dialog dialog-1 dialog-50';
        },
        formatDate(date, format){
            let dateMoment = moment(date, 'DD.MM.YYYY HH:mm:ss');

            if (dateMoment != null && dateMoment.isValid()) {
                return dateMoment.format(format);            
            }

            return null;
        },
        async addComment() {
            let validate_result = this.$refs.form.validate();
            if(validate_result){
                this.savingComment = true;
                await this.AddDocumentCommentFromTab(this.comment);
                this.comment = null;      
                this.savingComment = false; 
            }else{
                this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
            }
        },
        async handleEnterTextarea (e) {
            if (e.ctrlKey) this.addComment() 
        },
        addFile() {
            this.AddFileToComment();
        },
        isProgressVisible (comment) {
            return !!comment.Attachment?.Progress
        },
        isMessageVisible (comment) {
            return !!comment.Attachment?.Message
        },
        commentAttachmentLength(comment) {
            return comment.Attachment?.Length
        },
        async loadAttchment(item) {
            
            // формируем URL
            let url = `api/download/attachment?id=${item.Id}&name=${item.Name}&mode=${process.env.NODE_ENV}&isCommon=${this.$store.getters['actionsource/isDataSourceCommon']}`;
            
            //если формат вложения поддерживает предпросмотр - открываем в модальном окне
            if (this.pictureExtensions.includes(item.Extension.replace('.', ''))) {
                url = `api/download/attachment?id=${item.Id}&name=${item.Name}&mode=production&token=${this.$store.getters['auth/getUserInfo']?.token}&isCommon=${this.$store.getters['actionsource/isDataSourceCommon']}`;
                await this.$refs.CommentPicturePreviewRef.open(url, item);
            }
            else {
                if (process.env.NODE_ENV === "production") {
                    // этот механизм будет работать если хост VUE app и хост API одинаковый
                    url += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = item.Name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                // для разработки
                else {                
                    let response = await httpAPI({ url, method: 'GET', responseType: 'blob' });

                    if (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', item.Name);
                        document.body.appendChild(link);
                        link.click();
                    }
                }
            }
        },  
        async deleteAttchment(comment) {

            if (comment.Attachment?.Message) {
                this.updateDataSource();
            }
            else {
                this.$notify.confirm(
                    i18n.t('Вы_действительно_хотите_удалить_вложение_filename?.message', { filename: comment.Attachment.Name }),
                    async () => 
                    {
                        let response = await httpAPI({
                            url: `api/actions/deletedocumentcomment?id=${comment.Id}&attachmentId=${comment.Attachment.Id}&documentId=${this.getDocumentId}`,
                            method: 'GET',
                            headers: { 'isCommon': this.$store.getters['actionsource/isDataSourceCommon'] }
                        });

                        if (response) {
                            this.updateDataSource();
                            this.updatePageCounter({ tabId: '0202150', increase: false });
                        }
                    }
                )
            }
        },
        produceGlobalEvent(id) {
            if (id)
                this.$eventBus.$emit('show-workplace-information', id);
        },
        imgPreviewURL(id, name){
            return `api/download/attachment?id=${id}&name=${name}&mode=production&token=${this.$store.getters['auth/getUserInfo']?.token}&height=100&width=100&isCommon=${this.$store.getters['actionsource/isDataSourceCommon']}`;
        },
        isPicture(extension)
        {
            if (extension)
                return this.pictureExtensions.includes(extension.replace('.', ''));
            
            return false;
        },
    },
    created(){
        this.setIsActive(true);
        this.update();
    },
    destroyed() {
        this.setIsActive(false);
    },
}
</script>
